import { getCognitoDataFromStorage } from '@/utils/auth';
import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloQueryResult,
  FetchResult,
  MutationOptions,
  QueryOptions,
  OperationVariables,
  DocumentNode,
} from '@apollo/client/core';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

if (process.env.REACT_APP_ENV === 'local-dev') {
  loadDevMessages();
  loadErrorMessages();
}

// HTTP Link for Queries and Mutations
const httpLink = createHttpLink({
  uri: `${BACKEND_URL}graphql`,
});

// GraphQL-WS Link for Subscriptions
const createWsLink = async () => {
  const cognito = await getCognitoDataFromStorage();

  const wsClient = createClient({
    url: `${BACKEND_URL.replace(/^http/, 'ws')}graphql`, // Wechsel von HTTP zu WS
    connectionParams: {
      token: cognito?.session?.getAccessToken().getJwtToken(),
      'client-id': cognito?.client_id,
      'user-pool-id': cognito?.user_pool_id,
      'auth-user-pool-id': cognito?.auth_user_pool_id,
      'auth-client-id': cognito?.auth_client_id,
      'user-id': cognito?.user_id,
      domain: document.location.origin,
    },
    lazy: true, // Verbindung wird nur bei Bedarf aufgebaut
    keepAlive: 12000, // Ping-Intervalle in Millisekunden
    on: {
      closed: () => {
        console.warn('WebSocket connection closed.');
      },
      error: (error) => {
        console.error('WebSocket connection error:', error);
      },
    },
    retryAttempts: Infinity,
  });

  return new GraphQLWsLink(wsClient);
};

// Auth Link for attaching headers
const createAuthLink = async () => {
  const cognito = await getCognitoDataFromStorage();
  return setContext((_, { headers }) => ({
    headers: {
      ...headers,
      token: cognito?.session?.getAccessToken().getJwtToken(),
      'client-id': cognito?.client_id,
      'user-pool-id': cognito?.user_pool_id,
      'auth-user-pool-id': cognito?.auth_user_pool_id,
      'auth-client-id': cognito?.auth_client_id,
      'user-id': cognito?.user_id,
      domain: document.location.origin,
    },
  }));
};

// Apollo Client setup
const setupApolloClient = async () => {
  const authLink = await createAuthLink();
  const wsLink = await createWsLink();

  // Split Link: Queries/Mutations => HTTP, Subscriptions => WebSocket
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink, // Subscriptions über WebSocket
    authLink.concat(httpLink), // Queries und Mutations über HTTP
  );

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });
};

// Query function
export const query = async <T>(
  data: QueryOptions<OperationVariables, T>,
): Promise<ApolloQueryResult<T>> => {
  try {
    const client = await setupApolloClient();
    return await client.query<T>(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

// Mutation function
export const mutate = async <T>(
  data: MutationOptions<T, OperationVariables>,
): Promise<FetchResult<T>> => {
  try {
    const client = await setupApolloClient();
    return await client.mutate<T>(data);
  } catch (error) {
    console.error('An error occurred');
    return Promise.reject(error);
  }
};

// Subscription function
export const subscribe = async <T>(
  query: DocumentNode, // GraphQL-Subscription Query
  variables?: OperationVariables,
  callback?: (data: T) => void,
  errorCallback?: (error: unknown) => void,
) => {
  try {
    const client = await setupApolloClient();
    const observable = client.subscribe<T>({
      query,
      variables,
    });

    return observable.subscribe({
      next: ({ data }) => {
        if (callback) callback(data as T);
      },
      error: (error) => {
        console.error('Subscription error:', error);
        if (errorCallback) errorCallback(error);
      },
    });
  } catch (error) {
    console.error('Subscription initialization error:', error);
    return Promise.reject(error);
  }
};
