import { Modal, Switch } from 'antd';
import { FC, useEffect, useState } from 'react';
import { ProForm } from '@ant-design/pro-components';
import { useIntl } from '@umijs/max';
import { FileMultipart, FileResponse, ProFormDragger } from '../fileDragger';
import { UploadFile } from 'antd/lib';
import { VBDocument } from '@/services/vertriebsbutler/file';
import { FetchResult } from '@apollo/client';
type UploadProps = {
  dir?: string;
  open: boolean;
  // folders: Array<string>;
  currentFolder: string;
  contact_id: string;
  project_id: string;
  campaign_id?: string;
  onClose: (isOk: boolean, data?: UploadData) => void;
};

type UploadData = {
  folder?: string;
  campaign_id?: string;
  subfolder?: string;
  files: Array<UploadFile<FileResponse>>;
  isPublicCampaignFile: boolean;
};

export const UploadModal: FC<UploadProps> = (props) => {
  const [form] = ProForm.useForm<UploadData>();
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const completeUpload = (
    s3Key?: string,
    mime?: string,
    filename?: string,
    multipart?: FileMultipart,
  ) => {
    if (!s3Key || !filename || !mime) return;
    return VBDocument.completeUpload({
      contentType: mime,
      filename: filename,
      s3key: s3Key,
      backgroundProcess: {
        thumbnail: true,
      },
      multipart,
    });
  };

  useEffect(() => {
    form.setFieldsValue({ folder: props.currentFolder });
  }, [props.currentFolder]);

  return (
    <Modal
      title={intl.formatMessage({ id: 'component.fileExplorer.upload.title' })}
      open={props.open}
      okButtonProps={{ loading: loading }}
      onCancel={() => {
        props.onClose(false);
        form.resetFields();
      }}
      onOk={async () => {
        const data = await form.validateFields();

        const promises: Array<Promise<FetchResult<boolean>> | undefined> = [];
        if (data.files) {
          const filesPromise = data.files.map((file) =>
            completeUpload(
              file.response?.s3key,
              file.response?.mime,
              file.response?.name,
              file.response?.multipart,
            ),
          );
          promises.push(...filesPromise);
        }

        await Promise.all(promises);

        if (!data.folder || (data.folder === '/' && data.subfolder)) {
          data.folder = data.subfolder;
          data.subfolder = undefined;
        }
        props.onClose(true, data);
        form.resetFields();
      }}
      okText={intl.formatMessage({ id: 'global.action.upload' })}
      width="50%"
    >
      <ProForm
        form={form}
        rowProps={{
          gutter: [16, 0],
        }}
        grid={false}
        submitter={false}
      >
        <ProForm.Item name={'campaign_id'} hidden={true} initialValue={props.campaign_id} />
        <ProForm.Item
          name="folder"
          label={intl.formatMessage({ id: 'component.fileExplorer.folder' })}
          // rules={[
          //   {
          //     required: true,
          //     message: intl.formatMessage({ id: 'component.fileExplorer.folder.required' }),
          //   },
          // ]}
          hidden={true}
          initialValue={props.currentFolder}
        >
          {/* <ProFormSelect options={folders} /> */}
        </ProForm.Item>

        {/* <ProForm.Item
          shouldUpdate={(prevValues, currentValues) => prevValues.folder !== currentValues.folder}
          noStyle
        >
          {() => (
            <ProFormText
              name="subfolder"
              label={intl.formatMessage({ id: 'component.fileExplorer.subfolder' })}
              rules={[
                {
                  required: form.getFieldValue('folder') === '/',
                  message: intl.formatMessage({ id: 'component.fileExplorer.subfolder' }),
                },
              ]}
            />
          )}
        </ProForm.Item> */}
        <ProFormDragger
          customCompleteJob={true}
          folder={`contact/${props.contact_id}/project/${props.project_id}`}
          rules={[
            { required: true, message: intl.formatMessage({ id: 'component.file.required' }) },
          ]}
          title={intl.formatMessage({
            id: 'component.file.title',
          })}
          multiple={true}
          name={'files'}
          contact_id={props.contact_id}
          project_id={props.project_id}
          thumbnail={true}
          preInsert={false}
          filesUploaded={(done) => setLoading(!done)}
          uploadInit={() => setLoading(true)}
        />
        {props.campaign_id && (
          <ProForm.Item
            name={'isPublicCampaignFile'}
            label={'mit Kunden teilen'}
            initialValue={false}
          >
            <Switch />
          </ProForm.Item>
        )}
      </ProForm>
    </Modal>
  );
};
